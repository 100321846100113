.select-list-modal {
    top: 70px;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    padding: 20px;
    background-color: white; /* Cor de fundo branca */
    border-radius: 10px;
    color: black; /* Cor do texto dentro do modal */
    z-index: 998;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Sombra para destacar o modal */
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.select-list-container {
    display: flex;
    flex-direction: column;
}

.select-list-item {
    padding: 10px;
    margin: 5px 0;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.select-list-item:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Cor de fundo ao passar o mouse */
}

.select-list-empty {
    margin-top: 10px;
    color: #ccc;
}